<template>
  <div class="content">
    <h1>Frequently Asked Questions</h1>

    <!-- TODO lots of content improvement -->
    <p>
      Our mission is to simplify tax compliance processes such as tax form collection, tax
      ID validation and VAT number lookup. We carefully research compliance rules and tax
      ID structure and syntax, and design forms and algorithms that strictly apply those
      rules.
    </p>

    <br />

    <h5>What security measures do you have in place?</h5>

    <p>
      Security is especially critical when it comes to sensitive personal information.
      That is why our data transfers are secured with AES-256 encryption, access is
      restricted with identity and access management policies, and all of our endpoints
      are reviewed against the
      <a href="https://owasp.org/www-project-top-ten/">OWASP Top 10</a> awareness
      principles. Our infrastructure is designed for data redudancy and regularly backed
      up to ensure recoverability.
    </p>

    <br />

    <h5>Does Tax ID Pro store tax ID numbers sent in validation or lookup requests?</h5>

    <p>
      Tax ID Pro does <strong>not</strong> permanently store tax ID numbers sent to our
      validation or lookup service. When a tax ID number is transmitted to our API in a
      secure HTTP request, it is stored in memory for a few miliseconds while it is
      processed, then it is flushed from memory.
    </p>

    <br />

    <h5>What countries are supported by the validation service?</h5>
    <p>
      We support individual, entity, and VAT tax IDs for over 100 countries. For a
      complete list, please see our
      <router-link :to="{ name: 'Validation' }">supported countries</router-link>
      page.
    </p>
    <br />

    <h5>What is the difference between the validation and lookup services?</h5>
    <p>
      Our validation service tests numbers for proper formatting and conformity with
      checksum algorithms, where available. The validation service provides assurance that
      the number is valid, but it does not guarantee that the number is associated with a
      specific person or entity. To confirm the association between the number and the
      person or entity, a lookup service is required. If you use a separate lookup
      service, the validation service is helpful for screening numbers beforehand, as
      lookup services tend to be slower and allow fewer requests.
    </p>
    <p>
      We provide a lookup service for VAT numbers only. The lookup service will validate
      the number and also return a name and address for the requested VAT number. A
      <router-link :to="{ name: 'Lookup' }"
        >complete list of supported countries</router-link
      >
      is available in our documentation. <br />
    </p>
    <br />

    <h5>Why should I validate or look up tax ID numbers?</h5>
    <p>
      If you collect tax information in the course of your business, you should take steps
      to ensure that the information collected is accurate and complete. Strong validation
      can help to catch errors and fraud before it becomes a headache down the road.
    </p>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>